import _ from 'lodash';

const positions = {
    'permanent': {
        categories: ['permanent', 'temporary', 'parttime', 'other'],
        formInfo: {
            confidential: {
                titlePath: 'profile.confidential-keys.salary',
                positionKey: 'baseSalary',
            },
        },
        profileFields: { bonus: 0, equity: false },
        icon: 'fal fa-money-bill',
        step: 5000,
        default: 100000,
        max: 1000000,
    },
    'interim': {
        categories: ['interim', 'contract'],
        formInfo: {
            confidential: {
                titlePath: 'profile.confidential-keys.rate',
                positionKey: 'interimRate',
            },
        },
        icon: 'fal fa-star-half-alt',
        step: 50,
        default: 500,
        max: 2500,
    },
    'ned': {
        categories: ['ned'],
        formInfo: {
            confidential: {
                titlePath: 'profile.confidential-keys.fee',
                positionKey: 'nedRate',
            },
        },
        profileFields: { equity: false, probono: false, classifications: [] },
        icon: 'fal fa-briefcase',
        step: 2500,
        default: 15000,
        max: 150000,
    },
};

const positionTypes = Object.keys(positions);
const positionCategories = _.mapValues(positions, 'categories');
const positionCategoriesFlat = _.flatMap(positions, 'categories');

function getPositionType(category) {
    return _.findKey(positionCategories, positionType => positionType.includes(category));
}

function getPositionInfo(key, position) {
    const info = _.mapValues(positions, key);
    return _.get(info, getPositionType(position), info);
}

export {
    positions,
    positionTypes,
    positionCategories,
    positionCategoriesFlat,
    getPositionType,
    getPositionInfo,
};